import React from 'react';
import { Alert } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { formActions, getFormData, isContactFormSubmitSuccess } from '../../redux/slice/formData';
import { getPartnerSettings, getStepOverrides } from '../../redux/slice/ui';
import { checkAndUpdateProject } from '../../redux/thunks';
import { ModalContentOverride } from '../../typedefs';
import {
  E_DEN_LONG_NAME,
  E_DEN_PRIVACY_PAGE,
  E_DEN_TERMS_AND_CONDITIONS_PAGE,
  STEP_NAMES,
} from '../../utils/constants';
import { LayoutType, ServiceType, type ContactEmailType } from '../../utils/enums';
import FormUtil from '../../utils/formUtil';
import FormElement from '../common/FormElement';
import FormTextArea from '../common/FormTextArea';
import TermsAndConditions from '../common/TermsAndConditions';
import CombinedAddressAndZipInputs from '../location/CombinedAddressAndZipInputs';
import SubmitContactFormButton from '../modals/SubmitContactFormButton';

const DEFAULT_NON_REPAIR_INTRO = `Please fill out this form, and we will reach out to promptly schedule a home visit.`;
const DEFAULT_REPAIR_INTRO = `Please fill out this form, and we will reach out to promptly schedule a service call.`;
const DEFAULT_WHOLE_HOME_INTRO = `Looking for a quote to replace your central AC or furnace? Please fill out this form, and we will reach out promptly to schedule a home visit.`;
const DEFAULT_MULTIPLE_ROOMS_INTRO = `When considering multiple room solutions, it’s a good idea to let our zonal heating and cooling experts walk through the best system for your home. 
Often we can find single zone solutions that meet your multiple room needs, saving you lots of money. 
Submit your contact information below and we will be in touch with you shortly to discuss your project!`;

type ContactFormOwnProps = {
  emailType: ContactEmailType;
};

function mapStateToProps(state: RootState) {
  const { firstName, lastName, email, phone, repairDetails, serviceType, layoutType } = getFormData(state);

  const partnerSettings = getPartnerSettings(state);
  const { privacyPolicyUrl, termsConditionsUrl, name } = partnerSettings;
  const contentOverride = getStepOverrides(state, STEP_NAMES.INTRO);
  const submitSuccess = isContactFormSubmitSuccess(state);

  return {
    firstName,
    lastName,
    email,
    phone,
    repairDetails,
    contentOverride,
    serviceType,
    layoutType,
    privacyPolicyUrl: privacyPolicyUrl || E_DEN_PRIVACY_PAGE,
    termsConditionsUrl: termsConditionsUrl || E_DEN_TERMS_AND_CONDITIONS_PAGE,
    companyName: name || E_DEN_LONG_NAME,
    submitSuccess,
  };
}

const { updateField } = formActions;

const mapDispatchToProps = {
  updateField,
  checkAndUpdateProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type ContactFormProps = ContactFormOwnProps & ModalContentOverride & PropsFromRedux;

class ContactForm extends React.PureComponent<ContactFormProps> {
  handleFirstNameUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  handleLastNameUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  handleEmailUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  handlePhoneUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  handleRepairDetailsUpdate: (e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void;

  constructor(props: ContactFormProps) {
    super(props);
    this.handleFirstNameUpdate = FormUtil.createFieldHandler('firstName', props, { keepProjectData: true }).bind(this);
    this.handleLastNameUpdate = FormUtil.createFieldHandler('lastName', props, { keepProjectData: true }).bind(this);
    this.handleEmailUpdate = FormUtil.createFieldHandler('email', props, { keepProjectData: true }).bind(this);
    this.handlePhoneUpdate = FormUtil.createFieldHandler('phone', props, { keepProjectData: true }).bind(this);
    this.handleRepairDetailsUpdate = FormUtil.createFieldHandler('repairDetails', props, {
      keepProjectData: true,
    }).bind(this);
  }

  render() {
    const { firstName, lastName, email, phone, repairDetails, submitSuccess, emailType } = this.props;
    const intro = this.getIntroText();
    const detailsLabel = this.getDetailsInputLabel();
    const detailsDescription = this.getDetailsInputDescription();
    return (
      <div className="ContactForm">
        <p>{intro}</p>
        <FormElement
          name="firstName"
          autoComplete="given-name"
          label="First Name"
          type="text"
          className="wide"
          value={firstName}
          required
          onChange={this.handleFirstNameUpdate}
        />
        <FormElement
          name="lastName"
          autoComplete="family-name"
          label="Last Name"
          type="text"
          className="wide"
          value={lastName}
          required
          onChange={this.handleLastNameUpdate}
        />
        <CombinedAddressAndZipInputs streetAddressBehavior={{ keepProjectData: true }} />
        <FormElement
          name="email"
          autoComplete="email"
          label="Email"
          type="text"
          className="wide"
          value={email}
          required
          onChange={this.handleEmailUpdate}
        />
        <FormElement
          name="phone"
          autoComplete="tel"
          label="Phone"
          type="text"
          className="wide"
          value={phone}
          required
          onChange={this.handlePhoneUpdate}
        />
        <FormTextArea
          name="repairDetails"
          label={detailsLabel}
          description={detailsDescription}
          className="wide"
          rows={3}
          value={repairDetails}
          required
          onChange={this.handleRepairDetailsUpdate}
        />
        <TermsAndConditions />
        {submitSuccess && (
          <Alert variant="success" className="mt-2">
            Request successfully submitted! We'll get back to you as soon as possible. You can safely close the page.
          </Alert>
        )}
        <div className="d-flex justify-content-end">
          <SubmitContactFormButton className="align-right" emailType={emailType} />
        </div>
      </div>
    );
  }

  private getIntroText() {
    const { serviceType, contentOverride } = this.props;
    if (serviceType !== ServiceType.REPAIR) {
      return this.getIntroForLayout() || DEFAULT_NON_REPAIR_INTRO;
    }

    return contentOverride?.repairIntro || DEFAULT_REPAIR_INTRO;
  }

  private getIntroForLayout() {
    const { layoutType, contentOverride } = this.props;

    switch (layoutType) {
      case LayoutType.BOILER:
        return contentOverride?.boilerIntro || DEFAULT_NON_REPAIR_INTRO;
      case LayoutType.WHOLE_HOME:
        return contentOverride?.wholeHomeIntro || DEFAULT_WHOLE_HOME_INTRO;
      case LayoutType.MULTIPLE_ROOMS:
        return contentOverride?.multipleRoomIntro || DEFAULT_MULTIPLE_ROOMS_INTRO;
      case LayoutType.SINGLE_ROOM:
        return contentOverride?.singleRoomIntro || DEFAULT_NON_REPAIR_INTRO;
    }

    return undefined;
  }

  private getDetailsInputLabel() {
    const { serviceType } = this.props;
    if (serviceType === ServiceType.REPAIR) {
      return 'Please describe the issue';
    }

    return 'Please include any other details that may be helpful.';
  }

  private getDetailsInputDescription() {
    const { serviceType } = this.props;
    if (serviceType === ServiceType.REPAIR) {
      return 'No heat/cooling, leaks, noise';
    }
    return undefined;
  }
}

export default connector(ContactForm);
