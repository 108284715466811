import { createAsyncThunk, type GetThunkAPI } from '@reduxjs/toolkit';
import { debounce, flatMap, head, includes, isEmpty, map, sortBy, uniq, values } from 'lodash-es';
import type { NextRouter } from 'next/router';
import { fetchFinancingApiThunk } from '../api/FinancingAPI';
import { fetchNeighborhoodInfoApiThunk } from '../api/NeighborhoodAPI';
import { fetchPartnerApiThunk } from '../api/PartnerAPI';
import {
  createProjectApiThunk,
  reloadProjectApiThunk,
  saveProjectApiThunk,
  submitContactFormApiThunk,
  submitProjectApiThunk,
} from '../api/ProjectsAPI';
import { fetchPropertyApiThunk } from '../api/PropertyDataAPI';
import { fetchRebatesApiThunk } from '../api/RebatesAPI';
import PhotosAPI from '../api/admin/PhotosAPI';
import type {
  CheckAndUpdateArgs,
  EnhancedAuthenticationContext,
  FormFields,
  PhotoAPIPayload,
  ProjectsAPIPayload,
  RebatesAPIPayload,
  StepValidator,
  UtilityProvider,
} from '../typedefs';
import { ADMIN_PATH, EDEN_PARTNER_SLUG } from '../utils/constants';
import CookieUtil from '../utils/cookieUtil';
import { YesNoType, type ContactEmailType } from '../utils/enums';
import ErrorUtil from '../utils/errorUtil';
import mapsUtil from '../utils/mapsUtil';
import PathUtil from '../utils/pathUtil';
import StringUtil from '../utils/stringUtil';
import TrackingUtil from '../utils/trackingUtil';
import WindowUtil from '../utils/windowUtil';
import {
  getCompletionUrl,
  getFormValidator,
  getProjectSystemIds,
  getRecommendedProjectInstallerIds,
  getSummaryProjectId,
  hasFinancingErrors,
  hasPropertyDataErrors,
  hasRebateErrors,
  isCashSelected,
  validateAddress,
  validateContactForm,
  validateQuoteContactInfo,
} from './selectors';
import type { RootState } from './slice';
import { errorActions, getFieldErrors } from './slice/errors';
import { financingActions } from './slice/financing';
import {
  formActions,
  getFormData,
  getLayoutType,
  getServiceType,
  getTouchedFields,
  isInitialFormDataState,
} from './slice/formData';
import { mapsActions } from './slice/maps';
import { photosActions } from './slice/photos';
import {
  getInitialized,
  getRecommendedProjects,
  getRecommendedProjectsFormVersion,
  getUUID,
  projectActions,
} from './slice/projects';
import { rebateActions } from './slice/rebates';
import { getDefaultShowIncomeQualifiedRebatesSetting, getPartnerSlug, uiActions } from './slice/ui';
import type { AppDispatch } from './store';

type ThunkConfig = {
  dispatch: AppDispatch;
  state: RootState;
};

const INTERNAL_MODE_QUERY_STRING_NAME = 'internalMode';
const VERSION_QUERY_STRING_NAME = 'v';

const photosAPI = new PhotosAPI();

const setRegionDefaults = createAsyncThunk<void, ProjectsAPIPayload, ThunkConfig>(
  'regionDefaults/update',
  async (projectData, thunkApi) => {
    const state = thunkApi.getState();

    const formData = getFormData(state);
    if (projectData.defaults) {
      if (projectData.defaults.wholeHomeSystem !== undefined && formData.wholeHomeSystem === undefined) {
        // TODO seems like we should have more of a default value concept here, but for now just overriding an undefined value
        thunkApi.dispatch(
          formActions.updateSelector({ field: 'wholeHomeSystem', value: projectData.defaults.wholeHomeSystem }),
        );
      }
      if (projectData.defaults.ductedDelivery !== undefined && formData.ductedDelivery === undefined) {
        // TODO seems like we should have more of a default value concept here, but for now just overriding an undefined value
        thunkApi.dispatch(
          formActions.updateSelector({ field: 'ductedDelivery', value: projectData.defaults.ductedDelivery }),
        );
      }

      const defaultShowIncomeQualifiedRebates = getDefaultShowIncomeQualifiedRebatesSetting(state);
      if (
        defaultShowIncomeQualifiedRebates !== undefined &&
        defaultShowIncomeQualifiedRebates !== YesNoType.UNKNOWN &&
        formData.showIncomeQualifiedRebates === undefined
      ) {
        // TODO seems like we should have more of a default value concept here, but for now just overriding an undefined value
        thunkApi.dispatch(
          formActions.updateSelector({
            field: 'showIncomeQualifiedRebates',
            value: defaultShowIncomeQualifiedRebates === YesNoType.YES,
          }),
        );
      }
    }
  },
);

function buildContext(state: RootState) {
  const experiments = {}; // getExperimentsStore(state);
  const context: any = { experiments };

  const referrer = CookieUtil.getLandingPageReferrer();
  if (referrer && StringUtil.urlIncludesQueryData(referrer)) {
    context['referrer'] = referrer;
  }

  if (CookieUtil.isInternalMode()) {
    context['internal'] = 'true';
  }

  return context;
}

export const createProject = createAsyncThunk<void, void, ThunkConfig>('projects/create', async (_unused, thunkApi) => {
  const state = thunkApi.getState();
  const partnerSlug = getPartnerSlug(state);
  const context = buildContext(state);

  try {
    const response = await thunkApi
      .dispatch(
        createProjectApiThunk({
          context,
          partnerSlug,
        }),
      )
      .unwrap();

    CookieUtil.setUUID(partnerSlug, response.uuid);
  } catch (e) {
    thunkApi.dispatch(errorActions.setServerErrors(e));
  }
});

export const clearProjectAndRelatedData = createAsyncThunk<void, void, ThunkConfig>(
  'projects/clear',
  async (_unused, thunkApi) => {
    try {
      thunkApi.dispatch(projectActions.clearProjects());
      thunkApi.dispatch(rebateActions.clearRebates());
      thunkApi.dispatch(financingActions.clearFinancing());
    } catch (e) {}
  },
);

export const updateProjects = createAsyncThunk<void, CheckAndUpdateArgs | undefined, ThunkConfig>(
  'projects/update',
  async (args, thunkApi) => {
    if (args?.clearPlaceData) {
      thunkApi.dispatch(formActions.clearAutocompleteData());
    }
    const state = thunkApi.getState();
    const partnerSlug = getPartnerSlug(state);

    try {
      if (!args?.keepProjectData) {
        thunkApi.dispatch(projectActions.onLoadingStart());
      }
      thunkApi.dispatch(errorActions.clearAllErrors());

      const projectData = await callSaveProjectApiThunk(thunkApi).unwrap();

      CookieUtil.setUUID(partnerSlug, projectData.uuid);
      thunkApi.dispatch(setRegionDefaults(projectData));
      thunkApi.dispatch(fetchRebates());
      thunkApi.dispatch(fetchFinancing());
    } catch (e) {
      thunkApi.dispatch(errorActions.setServerErrors(e));
    } finally {
      if (!args?.keepProjectData) {
        thunkApi.dispatch(projectActions.onLoadingComplete());
      }
    }
  },
);

function callSaveProjectApiThunk(thunkApi: GetThunkAPI<ThunkConfig>) {
  const state = thunkApi.getState();
  const formData = getFormData(state);
  const selectedProjectId = getSummaryProjectId(state);
  const uuid = getUUID(state);
  const context = buildContext(state);
  const partnerSlug = getPartnerSlug(state);
  const showCash = isCashSelected(state);

  return thunkApi.dispatch(
    saveProjectApiThunk({
      formData,
      uuid,
      selectedProjectId,
      context,
      partnerSlug,
      showCash,
      version: `${CookieUtil.getLoadVersion()}`,
    }),
  );
}

export const fetchNeighborhoodInfo = createAsyncThunk<void, void, ThunkConfig>(
  'neighborhood/get',
  async (_unused, thunkApi) => {
    const state = thunkApi.getState();
    const formData = getFormData(state);
    const partnerSlug = getPartnerSlug(state);
    const { zipCode } = formData;

    if (!partnerSlug || !zipCode || zipCode.length !== 5) {
      clearSelectedElectricUtilityProviderIdIfNotValidUtilityProvider(formData, [], thunkApi);
      return;
    }

    const neighborhoodResult = await thunkApi
      .dispatch(fetchNeighborhoodInfoApiThunk({ zipCode, partnerSlug }))
      .unwrap();
    const { utilityProviders } = neighborhoodResult || {};

    clearSelectedElectricUtilityProviderIdIfNotValidUtilityProvider(formData, utilityProviders ?? [], thunkApi);
  },
);

function clearSelectedElectricUtilityProviderIdIfNotValidUtilityProvider(
  formData: FormFields,
  utilityProviders: UtilityProvider[],
  thunkApi: GetThunkAPI<ThunkConfig>,
) {
  const { selectedElectricUtilityProviderId } = formData;
  if (selectedElectricUtilityProviderId) {
    const utilityProviderIds = map(utilityProviders, (utility) => utility.providerId);
    if (!includes(utilityProviderIds, selectedElectricUtilityProviderId)) {
      thunkApi.dispatch(formActions.updateSelector({ field: 'selectedElectricUtilityProviderId', value: undefined }));
    }
  }
}

export const fetchRebates = createAsyncThunk<void, void, ThunkConfig>('rebates/get', async (_unused, thunkApi) => {
  const state = thunkApi.getState();
  if (hasRebateErrors(state)) return;

  const formData = getFormData(state);
  const systemConfigurationIds: string[] = getProjectSystemIds(state);
  if (systemConfigurationIds.length === 0) return;

  const recommendations = getRecommendedProjects(state);
  const uuid = getUUID(state);
  const formVersion = getRecommendedProjectsFormVersion(state);
  const fetchRebatesPayload = { formData, recommendations, formVersion, uuid };
  const rebateApiPayload = await thunkApi.dispatch(fetchRebatesApiThunk(fetchRebatesPayload)).unwrap();

  const { selectedElectricUtilityProviderId } = formData;
  if (!selectedElectricUtilityProviderId) {
    setSelectedElectricUtilityProviderIdFromRebates(rebateApiPayload, thunkApi);
  }
});

async function setSelectedElectricUtilityProviderIdFromRebates(
  rebateApiPayload: RebatesAPIPayload,
  thunkApi: GetThunkAPI<ThunkConfig>,
) {
  const allElectricUtilityProviderIds = flatMap(
    values(rebateApiPayload.rebatesAndEligibility),
    (rebatesAndEligibility) => rebatesAndEligibility.electricUtilityProviderIds,
  );
  const electricUtilityProviderIds = uniq(sortBy(allElectricUtilityProviderIds));
  const firstElectricUtilityProviderId = head(electricUtilityProviderIds);

  if (firstElectricUtilityProviderId) {
    thunkApi.dispatch(
      formActions.updateSelector({
        field: 'selectedElectricUtilityProviderId',
        value: firstElectricUtilityProviderId,
      }),
    );
    callSaveProjectApiThunk(thunkApi);
  }
}

export const fetchFinancing = createAsyncThunk<void, void, ThunkConfig>('financing/get', async (_unused, thunkApi) => {
  const state = thunkApi.getState();
  if (hasFinancingErrors(state)) return;

  const formData = getFormData(state);
  const partnerSlug = getPartnerSlug(state);
  const installerIds = getRecommendedProjectInstallerIds(state);
  const uuid = getUUID(state);
  const formVersion = getRecommendedProjectsFormVersion(state);
  const fetchFinancingPayload = { formData, partnerSlug, installerIds, formVersion, uuid };
  thunkApi.dispatch(fetchFinancingApiThunk(fetchFinancingPayload));
});

export const fetchPhotos = createAsyncThunk<void, EnhancedAuthenticationContext, ThunkConfig>(
  'photos/get',
  async (enhancedAthContext, thunkApi) => {
    try {
      const authContext = await enhancedAthContext.getAuthentication();
      const photos: PhotoAPIPayload[] = await photosAPI.fetchPhotos(authContext);
      thunkApi.dispatch(photosActions.setPhotos(photos));
    } catch (e: any) {
      ErrorUtil.logError(e);
    }
  },
);

export const initializePartnerData = createAsyncThunk<string, string | undefined, ThunkConfig>(
  'partnerData/init',
  async (partnerSlug, thunkApi) => {
    if (!partnerSlug || includes([EDEN_PARTNER_SLUG, ADMIN_PATH], partnerSlug)) {
      thunkApi.dispatch(uiActions.initializeUiStateForEden());
      return EDEN_PARTNER_SLUG;
    }

    thunkApi.dispatch(uiActions.initializeUiStateForPartnerSlug(partnerSlug));
    return partnerSlug;
  },
);

export const fetchPartnerData = createAsyncThunk<void, void, ThunkConfig>(
  'partnerData/get',
  async (_unused, thunkApi) => {
    const state = thunkApi.getState();
    const partnerSlug = getPartnerSlug(state);
    if (partnerSlug !== EDEN_PARTNER_SLUG) {
      await thunkApi.dispatch(fetchPartnerApiThunk({ partnerSlug }));
    }
  },
);

function persistInternalModeFlagIfSetByQueryString() {
  const internalModelStr = WindowUtil.getFirstFromQueryString(INTERNAL_MODE_QUERY_STRING_NAME);
  if (internalModelStr) {
    CookieUtil.setInternalMode();
  }
}

function setLoadCalcV2FlagIfSetByQueryString() {
  const versionQueryString = WindowUtil.getFirstFromQueryString(VERSION_QUERY_STRING_NAME);
  if (versionQueryString) {
    CookieUtil.setLoadVersion(versionQueryString);
  }
}

export const initializeApp = createAsyncThunk<void, NextRouter, ThunkConfig>(
  'projects/load',
  async (router, thunkApi) => {
    let state = thunkApi.getState();
    const initialized = getInitialized(state);
    if (initialized) {
      return;
    }

    persistInternalModeFlagIfSetByQueryString();
    setLoadCalcV2FlagIfSetByQueryString();

    const browserPath = WindowUtil.getLocationPath();
    const partnerSlug = PathUtil.getPartnerSlug(browserPath);
    if (!partnerSlug) {
      return;
    }

    thunkApi.dispatch(projectActions.setInitialized(true));
    thunkApi.dispatch(loadMaps());

    await thunkApi.dispatch(initializePartnerData(partnerSlug));
    thunkApi.dispatch(formActions.initializeLayoutTypeFromPathname(browserPath));

    // This does not result in an internal page_view event for the current page.
    // A page_view event is explicitly sent after tracking is initialized in _app.tsx.
    TrackingUtil.configureRouterToSendPageViewEvents(router);

    state = thunkApi.getState();
    await thunkApi.dispatch(fetchPartnerData());

    const uuid = WindowUtil.getCustomerUuid() || CookieUtil.getUUID(partnerSlug);

    if (!uuid) {
      try {
        thunkApi.dispatch(createProject() as any);
      } catch (e) {
        thunkApi.dispatch(errorActions.setServerErrors(e));
      }

      return;
    }

    thunkApi.dispatch(projectActions.setUUID(uuid));
    try {
      await thunkApi.dispatch(reloadProjectApiThunk({ uuid, partnerSlug })).unwrap();

      const recentState = thunkApi.getState();
      const isInitialState = isInitialFormDataState(recentState);
      const { zipCode } = getFormData(recentState);

      if (zipCode) {
        await thunkApi.dispatch(fetchNeighborhoodInfo());
      }

      if (!isInitialState) {
        thunkApi.dispatch(updateProjects());
      }
    } catch (e) {
      thunkApi.dispatch(errorActions.setServerErrors(e));
    }
  },
);

export const submitProject = createAsyncThunk<void, void, ThunkConfig>('projects/submit', async (_unused, thunkApi) => {
  const state = thunkApi.getState();
  const uuid = getUUID(state);
  if (!uuid) {
    return;
  }
  await thunkApi.dispatch(submitProjectApiThunk(uuid)).unwrap();
  TrackingUtil.quoteRequest();
  await thunkApi.dispatch(redirectToCompletionUrl()).unwrap();
});

export const redirectToCompletionUrl = createAsyncThunk<void, void, ThunkConfig>(
  'projects/redirect',
  async (_unused, thunkApi) => {
    const state = thunkApi.getState();
    const completionUrl = getCompletionUrl(state);
    if (completionUrl) {
      setTimeout(() => {
        if (window.top && window.top.location !== window.self.location) {
          window.top.location.href = completionUrl;
        } else {
          window.location.href = completionUrl;
        }
      }, 1000);
    }
  },
);

export const submitContactForm = createAsyncThunk<void, ContactEmailType, ThunkConfig>(
  'projects/submit-repair',
  async (emailType, thunkApi) => {
    const state = thunkApi.getState();
    const uuid = getUUID(state);
    if (!uuid) {
      return;
    }

    try {
      await thunkApi.dispatch(submitContactFormApiThunk({ uuid, emailType })).unwrap();
      TrackingUtil.serviceRequest();
    } catch (e) {
      thunkApi.dispatch(errorActions.setServerErrors(e));
    }
  },
);

export const checkAndUpdateProject = createAsyncThunk<void, CheckAndUpdateArgs | undefined, ThunkConfig>(
  'forms/error-check',
  debounce(async (args: CheckAndUpdateArgs | undefined, thunkApi) => {
    const state = thunkApi.getState();
    const formData = getFormData(state);
    const touchedFields = getTouchedFields(state);
    const serviceType = getServiceType(state);
    const layout = getLayoutType(state);

    const validator = getFormValidator(formData, touchedFields, serviceType, layout);
    const errors = validator.validate();

    if (!args?.keepProjectData) {
      thunkApi.dispatch(projectActions.clearProjects());
    }

    await thunkApi.dispatch(errorActions.setLocalErrors(errors));
    const fieldErrors = getFieldErrors(thunkApi.getState());

    if (isEmpty(fieldErrors)) {
      thunkApi.dispatch(updateProjects(args));
    }
  }, 500),
);

const handleErrors = (hasErrors: boolean, onSuccess: () => void) => {
  if (!hasErrors) {
    onSuccess();
  } else {
    // This is using set timeout because the dom content is changing during this call. Refs wouldn't need the timeout, but they'd be less dynamic here
    setTimeout(() => {
      const invalidElems = document.getElementsByClassName('is-invalid');
      const invalidElemArr: HTMLElement[] = [].slice.call(invalidElems);
      const visible = invalidElemArr.filter((el: HTMLElement) => {
        // jQuery-like :visible selector
        return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
      });
      if (visible.length > 0) {
        visible[0].scrollIntoView();
      }
    }, 0);
  }
};

async function saveInitialFormDataBecauseUserNavigatedToNextStep(dispatch: AppDispatch) {
  await dispatch(updateProjects({ keepProjectData: true }));
  dispatch(formActions.setIsInitialFormDataStateToFalse());
}

export const checkStep = createAsyncThunk<void, { stepValidation: StepValidator; onSuccess: () => void }, ThunkConfig>(
  'forms/next-check',
  async (opts, thunkApi) => {
    const state = thunkApi.getState();

    const onSuccess = () => {
      if (isInitialFormDataState(state)) {
        saveInitialFormDataBecauseUserNavigatedToNextStep(thunkApi.dispatch);
      }

      opts.onSuccess();
    };

    const result = opts.stepValidation(state);
    thunkApi.dispatch(errorActions.setLocalErrors(result.errors));
    handleErrors(result.hasErrors, onSuccess);
  },
);

export const validateAddressThunk = createAsyncThunk<void, void, ThunkConfig>(
  'forms/validate-address',
  async (opts, thunkApi) => {
    const state = thunkApi.getState();
    const result = validateAddress(state);
    thunkApi.dispatch(errorActions.setLocalErrors(result.errors));
  },
);

export const checkQuoteContact = createAsyncThunk<void, { onSuccess: () => void }, ThunkConfig>(
  'forms/quote-contact-check',
  async (opts, thunkApi) => {
    const state = thunkApi.getState();
    const result = validateQuoteContactInfo(state);
    thunkApi.dispatch(errorActions.setLocalErrors(result.errors));
    handleErrors(result.hasErrors, opts.onSuccess);
  },
);

export const checkContactForm = createAsyncThunk<void, { onSuccess: () => void }, ThunkConfig>(
  'forms/contact-form-check',
  async (opts, thunkApi) => {
    const state = thunkApi.getState();
    const result = validateContactForm(state);
    thunkApi.dispatch(errorActions.setLocalErrors(result.errors));
    handleErrors(result.hasErrors, opts.onSuccess);
  },
);

export const loadMaps = createAsyncThunk<void, void, ThunkConfig>('maps/get', async (_, thunkApi) => {
  let success = false;
  thunkApi.dispatch(mapsActions.setMapsState({ loading: true, success }));

  try {
    success = await mapsUtil.loadGMaps();
  } catch (e) {
    success = false;
  }

  thunkApi.dispatch(mapsActions.setMapsState({ loading: false, success }));
});

export const fetchPropertyData = createAsyncThunk<void, void, ThunkConfig>(
  'propertyData/get',
  async (_unused, thunkApi) => {
    const reduxState = thunkApi.getState();
    if (hasPropertyDataErrors(reduxState)) return;
    thunkApi.dispatch(errorActions.clearAllErrors());

    const { streetAddress, city, state, zipCode } = getFormData(reduxState);

    await thunkApi
      .dispatch(
        fetchPropertyApiThunk({
          streetAddress: streetAddress || '',
          city,
          state,
          zipCode,
        }),
      )
      .unwrap();
    TrackingUtil.propertyDataAutoload();
    thunkApi.dispatch(checkAndUpdateProject());
  },
);
