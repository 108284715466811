import { getPartnerLicenseNumber } from '../../redux/slice/ui';
import { useAppSelector } from '../../redux/store';

type ContactorLicenseProps = {
  className?: string;
};

export default function ContactorLicense({ className }: ContactorLicenseProps) {
  const licenseNumber = useAppSelector(getPartnerLicenseNumber);
  if (licenseNumber) {
    return <div className={className}>License #{licenseNumber}</div>;
  }
  return null;
}
