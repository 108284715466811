import { map, padStart } from 'lodash-es';
import { NON_BREAKING_HYPHEN } from './constants';

const regexBase = '(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?';

const formatCurrency = (input: number, decimalPlaces: number = 0) => {
  const dollarFormatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
  return dollarFormatter.format(input).replace('-', NON_BREAKING_HYPHEN);
};

const formatIntegerDimension = (dimension?: number) => {
  return `${dimension ? dimension.toFixed(0) : '???'}"`;
};

const formatFractionalDimension = (dimension?: number) => {
  if (!dimension) return '???';

  const roundedDim = Math.round((dimension + Number.EPSILON) * 100) / 100;
  return roundedDim.toFixed(2).toString();
};

function convertToURL(str: string): URL | undefined {
  if (!str) return undefined;
  try {
    const url = new URL(str);
    return url;
  } catch (err) {
    return undefined;
  }
}

const StringUtil = {
  convertToURL,

  isValidUrl(str: string) {
    const url = convertToURL(str);
    return !!url;
  },

  urlIncludesQueryData(str: string) {
    const url = convertToURL(str);
    if (!url || !url.search) return false;
    return url.search.length > 1; // The ? will be in this string, so we check for more than it.
  },

  isPhone(input: string) {
    return new RegExp('\\s*' + regexBase + '\\s*', 'g').test(input);
  },

  hash(input: string) {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  },

  formatCurrency,

  formatCurrencyRange(minInput: number, maxInput: number | undefined, decimalPlaces: number = 0) {
    const min = formatCurrency(minInput, decimalPlaces);
    if (minInput === maxInput || maxInput === undefined) {
      return min;
    }
    const max = formatCurrency(maxInput, decimalPlaces);
    return `${min} - ${max}`;
  },

  formatNumber(input: number, decimalPlaces: number = 0) {
    const numberFormatter = Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
    return numberFormatter.format(input).replace('-', NON_BREAKING_HYPHEN);
  },

  formatNumberForDisplay(input: number | undefined, decimalPlaces: number = 0) {
    if (!input && input !== 0) {
      return '--';
    }

    return StringUtil.formatNumber(input, decimalPlaces);
  },

  formatIntegerDimensions({ height, width, depth }: { height?: number; width?: number; depth?: number }) {
    if (!height && !width && !depth) return undefined;

    const heightString = `H ${formatIntegerDimension(height)}`;
    const widthString = `W ${formatIntegerDimension(width)}`;
    const depthString = `D ${formatIntegerDimension(depth)}`;
    return `${heightString} / ${widthString} / ${depthString}`;
  },

  formatFractionalDimensions({ height, width, depth }: { height?: number; width?: number; depth?: number }) {
    const formattedHeight = formatFractionalDimension(height);
    const formattedWidth = formatFractionalDimension(width);
    const formattedDepth = formatFractionalDimension(depth);
    return `H ${formattedHeight} / W ${formattedWidth} / D ${formattedDepth}`;
  },

  formatPhoneNumber(phoneNumber: string) {
    // Based on https://stackoverflow.com/a/8358141
    const cleaned = phoneNumber.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  },

  replaceAll(str: string, search: string, replace: string) {
    return str.split(search).join(replace);
  },

  pluralize(str: string, count?: number): string {
    if (count === 1) return str;
    return str + 's';
  },

  formatEfficiencyNumberForDisplay(num?: number) {
    if (!num && num !== 0) {
      return '--';
    }

    const roundedNum = Math.round((num + Number.EPSILON) * 10) / 10;
    return roundedNum.toString();
  },

  formatIntegerAsPercentage(num?: number) {
    if (!num) {
      return '--';
    }

    const roundedNum = Math.round(num);
    return `${roundedNum}%`;
  },

  convertEnumValuesToStrings<T extends Record<string | number, string | number>>(
    values: T[keyof T][],
    enumType: T,
    undefinedString: string = 'not set',
  ): string[] {
    return map(values, (value) => StringUtil.convertEnumValueToString(value, enumType, undefinedString));
  },

  convertEnumValueToStringOrUndefined<T extends Record<string | number, string | number>>(
    value: T[keyof T] | undefined | null,
    enumType: T,
  ): string | undefined {
    if (value === undefined || value === null || (typeof value !== 'string' && typeof value !== 'number')) {
      return undefined;
    }

    if (typeof value === 'string') {
      return value;
    }
    if (enumType[value] === undefined) {
      return undefined;
    }

    return `${enumType[value]}`;
  },

  convertEnumValueToString<T extends Record<string | number, string | number>>(
    value: T[keyof T] | undefined | null,
    enumType: T,
    undefinedString: string = 'not set',
  ): string {
    const result = StringUtil.convertEnumValueToStringOrUndefined<T>(value, enumType);
    return result ?? undefinedString;
  },

  formatHumanReadableShortDatetime(date: Date) {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    };
    return date.toLocaleString('en-US', options);
  },

  formatShortDateString(date: Date = new Date()): string {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${date.getFullYear()}-${padStart(`${month}`, 2, '0')}-${padStart(`${day}`, 2, '0')}`;
  },

  formatNoiseMaxMinString(noiseMax?: number, noiseMin?: number) {
    const max = noiseMax ? noiseMax : '--';
    const min = noiseMin ? noiseMin : '--';
    return `Noise: ${max} max / ${min} min`;
  },

  capitalizeInitialCharacter(str: string | undefined, forceSubsequentLower?: boolean) {
    if (!str) return str;
    const afterText = forceSubsequentLower ? str.substring(1).toLowerCase() : str.substring(1);
    return str.charAt(0).toUpperCase() + afterText;
  },

  toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  },

  toString(val: any) {
    // Number-specific variant is in NumberUtil.
    if (val === undefined || val === null) return undefined;
    if (val.toString && val.toString instanceof Function) {
      return val.toString();
    }
    return `${val}`;
  },
};

export default StringUtil;
