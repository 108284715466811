import { includes } from 'lodash-es';
import type { EquipmentRecommendationAPIPayload, FormFields, PartnerSettings } from '../typedefs';
import { E_DEN_DOMAIN, E_DEN_SUCCESS_PAGE } from './constants';
import StringUtil from './stringUtil';
import WindowUtil from './windowUtil';

function getConfiguredCompletionUrl(
  project: EquipmentRecommendationAPIPayload | undefined,
  partnerSettings: Partial<PartnerSettings> | undefined,
) {
  if (partnerSettings?.completionUrl) {
    return partnerSettings.completionUrl;
  }

  if (!project) {
    return E_DEN_SUCCESS_PAGE;
  }

  const { completionUrl } = project.priceSnapshot.installer;
  if (!completionUrl) {
    return E_DEN_SUCCESS_PAGE;
  }

  return completionUrl;
}

function getRepairCompletionUrl(): URL | undefined {
  return WindowUtil.getNewUrl('Service-Requested');
}

function isEdenDomain(url: string): boolean {
  return includes(url, E_DEN_DOMAIN);
}

function getProjectCompletionUrl(
  project: EquipmentRecommendationAPIPayload | undefined,
  partnerSettings: Partial<PartnerSettings> | undefined,
  partnerSlug: string,
  formData: FormFields,
) {
  const completionUrl = getConfiguredCompletionUrl(project, partnerSettings);
  const url = StringUtil.convertToURL(completionUrl);
  if (!url) {
    return completionUrl;
  }

  if (isEdenDomain(completionUrl)) {
    const { firstName, lastName } = formData;

    url.searchParams.set('SQF_FIRSTNAME', firstName || '');
    url.searchParams.set('SQF_LASTNAME', lastName || '');
    url.searchParams.set('SQF_CONTRACTOR', partnerSlug || '');
  }

  if (partnerSettings?.iqSettings?.appendZipCodeToCompletionUrl) {
    const parameterName = partnerSettings?.iqSettings?.zipCodeParameterNameInCompletionUrl || 'zip';
    url.searchParams.set(parameterName, formData.zipCode || '');
  }

  return url.toString();
}

const PartnerUtil = {
  getConfiguredCompletionUrl,
  getRepairCompletionUrl,
  getProjectCompletionUrl,
};

export default PartnerUtil;
