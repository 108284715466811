import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { getStepOverrides, isWhiteLabelMode, showHeroPanelHeader } from '../../redux/slice/ui';
import { StepName } from '../../typedefs';
import ContactTelephoneNumber from './ContactTelephoneNumber';
import ContactorLicense from './ContractorLicense';
import Image from './Image';
import LogoHeaderItem from './LogoHeaderItem';
import YoutubeVideo from './YoutubeVideo';

type HeroPanelOwnProps = {
  heroYoutubeVideoId?: string;
  heroVideoTitle?: string;
  heroImageUrl?: string;
  heroImageAlt?: string;
  partnerQuote?: React.ReactElement;
  edenQuote?: React.ReactElement;
  stepName: StepName;
  imageWidth?: number;
  mobile?: boolean;
  className?: string;
};

function mapStateToProps(state: RootState, ownProps: HeroPanelOwnProps) {
  return {
    isWhiteLabelMode: isWhiteLabelMode(state),
    showHeroPanelHeader: showHeroPanelHeader(state),
    overrides: getStepOverrides(state, ownProps.stepName),
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type HeroPanelProps = HeroPanelOwnProps & PropsFromRedux;

class HeroPanel extends React.PureComponent<HeroPanelProps> {
  renderImage() {
    const { overrides, heroImageAlt, heroImageUrl, imageWidth } = this.props;
    const { photoAlt, photoUrl } = overrides;

    if (photoAlt && photoUrl) {
      return <Image src={photoUrl} alt={photoAlt} sizes={`${imageWidth}vw`} fill priority />;
    }

    if (heroImageAlt && heroImageUrl) {
      return <Image src={heroImageUrl} alt={heroImageAlt} sizes={`${imageWidth}vw`} fill priority />;
    }

    return null;
  }

  renderVideo() {
    const { overrides, heroYoutubeVideoId, heroVideoTitle } = this.props;
    const { youtubeVideoId, youtubeVideoTitle } = overrides;

    if (youtubeVideoId && youtubeVideoTitle) {
      return <YoutubeVideo id={youtubeVideoId} title={youtubeVideoTitle} />;
    }

    if (heroYoutubeVideoId && heroVideoTitle) {
      return <YoutubeVideo id={heroYoutubeVideoId} title={heroVideoTitle} />;
    }

    return null;
  }

  renderImageOrVideoWithPullQuote() {
    const { mobile } = this.props;
    const video = this.renderVideo();
    const image = !video && this.renderImage();

    const className = classNames({ Image: !video, Video: !image && !!video, mobile });
    return (
      <div className={className}>
        <div className="Accent"></div>
        {video || image}
        <div className="PullQuote">
          <div className="quoteContainer">{this.renderQuote()}</div>
        </div>
      </div>
    );
  }

  renderQuote() {
    const { isWhiteLabelMode, partnerQuote, edenQuote, overrides } = this.props;
    const { title, body } = overrides;
    if (!isWhiteLabelMode) {
      return edenQuote;
    }

    if (title || body) {
      return (
        <>
          {title && <h2>{title}</h2>}
          {body && <div className="quote fs-5">{body}</div>}
        </>
      );
    }
    return partnerQuote;
  }

  willRenderVideo() {
    const { overrides, heroYoutubeVideoId, heroVideoTitle } = this.props;
    const { youtubeVideoId, youtubeVideoTitle } = overrides;

    if ((youtubeVideoId && youtubeVideoTitle) || (heroYoutubeVideoId && heroVideoTitle)) {
      return true;
    }

    return false;
  }

  renderHeader() {
    const { isWhiteLabelMode, mobile, showHeroPanelHeader } = this.props;
    if (mobile || !showHeroPanelHeader) return null;

    return (
      <div
        className={classNames('header d-flex justify-content-between px-3', {
          'pt-5': !isWhiteLabelMode,
          'pt-3 pb-3': isWhiteLabelMode,
        })}
      >
        <LogoHeaderItem />
        {isWhiteLabelMode && (
          <div className="text-end">
            <ContactorLicense className="text-muted" />
            <ContactTelephoneNumber className="text-muted" />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { isWhiteLabelMode, stepName } = this.props;

    return (
      <div
        className={classNames(`${stepName} ${this.props.className} HeroPanel pt-2`, {
          partner: isWhiteLabelMode,
          video: this.willRenderVideo(),
        })}
      >
        {this.renderHeader()}
        {this.renderImageOrVideoWithPullQuote()}
      </div>
    );
  }
}

export default connector(HeroPanel);
