import autoBind from 'auto-bind';
import classNames from 'classnames';
import { WithRouterProps } from 'next/dist/client/with-router';
import { withRouter } from 'next/router';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { connect, ConnectedProps } from 'react-redux';
import { getPath, getStepContext, getSummaryProject, getTotalSteps } from '../../redux/selectors';
import type { RootState } from '../../redux/slice';
import { getEffectiveLayoutType } from '../../redux/slice/formData';
import { modalActions } from '../../redux/slice/modal';
import { getProjectId, projectActions } from '../../redux/slice/projects';
import {
  getPartnerSlug,
  getResultsNextButtonBehaviorSetting,
  getStepNextButtonLabelOverride,
} from '../../redux/slice/ui';
import { checkAndUpdateProject, checkStep, redirectToCompletionUrl } from '../../redux/thunks';
import { StepValidator } from '../../typedefs';
import { ModalType, ResultsNextButtonBehavior, StepType } from '../../utils/enums';
import StepUtil from '../../utils/stepUtil';
import TrackingUtil from '../../utils/trackingUtil';
import WindowUtil from '../../utils/windowUtil';

type FooterOwnProps = {
  stepValidation: StepValidator | null;
  currentStep: StepType;
};

function mapStateToProps(state: RootState, ownProps: FooterOwnProps) {
  const { currentStep } = ownProps;

  const layoutType = getEffectiveLayoutType(state);
  const partnerSlug = getPartnerSlug(state);
  const nextButtonLabelOverride = getStepNextButtonLabelOverride(state, currentStep, layoutType);
  const resultsNextButtonBehavior = getResultsNextButtonBehaviorSetting(state);
  const stepContext = getStepContext(state);
  return {
    defaultProject: getSummaryProject(state),
    experiments: {}, //getAllExperiments(state),
    partnerSlug,
    totalSteps: getTotalSteps(state),
    path: getPath(state),
    nextStepUrl: StepUtil.getNextStepUrl({ ...stepContext, currentStep }),
    prevStepUrl: StepUtil.getPrevStepUrl({ ...stepContext, currentStep }),
    currentStepNum: StepUtil.getCurrentStepNum({ ...stepContext, currentStep }),
    nextButtonLabelOverride,
    resultsNextButtonBehavior,
  };
}

const { openModal } = modalActions;
const { selectProjectId } = projectActions;

const mapDispatchToProps = {
  openModal,
  checkStep,
  selectProjectId,
  redirectToCompletionUrl,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type FooterProps = FooterOwnProps & PropsFromRedux & WithRouterProps;

class Footer extends React.PureComponent<FooterProps> {
  constructor(props: FooterProps) {
    super(props);
    autoBind(this);
  }

  onChangeStep(stepUrl?: string) {
    const newUrl = WindowUtil.getNewUrl(stepUrl);
    if (!newUrl) return; // TODO throw?
    this.props.router.push(newUrl);
  }

  onNextStep() {
    const { checkStep, stepValidation, nextStepUrl } = this.props;
    if (!nextStepUrl || !stepValidation) return;
    checkStep({
      stepValidation,
      onSuccess: () => {
        this.onChangeStep(nextStepUrl);
      },
    });
  }

  handleSelectProject() {
    const { defaultProject, selectProjectId, resultsNextButtonBehavior } = this.props;
    const projectId = getProjectId(defaultProject);
    if (!defaultProject || !projectId) {
      return;
    }

    this.sendSelectProjectEvent();
    selectProjectId(projectId);
    checkAndUpdateProject({ keepProjectData: true });

    if (resultsNextButtonBehavior === ResultsNextButtonBehavior.REDIRECT_TO_COMPLETION_URL) {
      this.redirectToCompletionUrl();
    } else {
      this.openQuoteSubmissionModal();
    }
  }

  redirectToCompletionUrl() {
    const { redirectToCompletionUrl } = this.props;
    redirectToCompletionUrl();
  }

  openQuoteSubmissionModal() {
    const { openModal, path } = this.props;
    TrackingUtil.pageView('/modal/quote', 'Modal - Contact Information', path);
    openModal({
      header: 'Schedule a Free Home Visit',
      type: ModalType.QUOTE,
    });
  }

  private sendSelectProjectEvent() {
    const { defaultProject } = this.props;
    if (!defaultProject) {
      return;
    }

    TrackingUtil.selectProjectEvent();
  }

  render() {
    const { experiments, nextStepUrl, prevStepUrl, currentStepNum, partnerSlug, nextButtonLabelOverride } = this.props;
    const nextButtonLabel = nextButtonLabelOverride || 'Next';
    const totalSteps = this.props.totalSteps || 0;
    const isLastStep = !nextStepUrl;
    const isFirstStep = !prevStepUrl;

    return (
      <div className="Footer">
        <Button
          variant="link link-primary"
          className={classNames('back', { invisible: isFirstStep })}
          type="button"
          onClick={() => currentStepNum && this.onChangeStep(prevStepUrl)}
        >
          &lt; Back
        </Button>

        <div className="text-muted">{`Step ${currentStepNum} / ${totalSteps}`}</div>

        {isLastStep ? (
          <Button
            variant="primary"
            className={classNames('quote', { invisible: totalSteps <= 1 })}
            type="button"
            onClick={this.handleSelectProject}
          >
            {nextButtonLabel}
          </Button>
        ) : (
          <Button
            variant="primary"
            className={classNames('next', { invisible: totalSteps <= 1 })}
            type="button"
            onClick={this.onNextStep}
          >
            {nextButtonLabel}
          </Button>
        )}
        <div style={{ display: 'none' }}>
          {JSON.stringify(experiments)}, {`partnerSlug: ${partnerSlug}`}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
