import { padStart } from 'lodash-es';

const NumberUtil = {
  roundToSignificantDigits(n: number | undefined, digits: number) {
    if (!n) return n;
    const factor = Math.pow(10, digits) * 1.0;
    return Math.round(n * factor) / factor;
  },
  roundToNearestHalf(n: number | undefined) {
    if (!n) return n;
    return Math.round(n * 2) / 2.0;
  },
  toInteger(val?: any): number | undefined {
    if (typeof val === 'number') return val;
    if (!val) return undefined;

    const str = `${val}`;
    const num = parseInt(str, 10);
    if (Number.isNaN(num)) {
      return undefined;
    }
    return num;
  },
  toFloat(val?: any): number | undefined {
    if (typeof val === 'number') return val;
    if (!val) return undefined;

    const str = `${val}`;
    const num = parseFloat(str);
    if (Number.isNaN(num)) {
      return undefined;
    }
    return num;
  },
  toString(val: number | undefined, options?: { padLeadingZerosToWidth?: number; defaultValue?: string }) {
    const { padLeadingZerosToWidth, defaultValue } = options || {};
    if (val === undefined || val === null) return defaultValue ?? undefined;
    if (val.toString && val.toString instanceof Function) {
      if (padLeadingZerosToWidth !== undefined) {
        return padStart(val.toString(), padLeadingZerosToWidth, '0');
      }
      return val.toString();
    }
    return `${val}`;
  },
};

export default NumberUtil;
