import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { filter, flatMap, isEmpty, mapValues, some, uniq, values } from 'lodash-es';
import type { RootState } from '.';
import { fetchRebatesApiThunk } from '../../api/RebatesAPI';
import type { EquipmentRecommendationAPIPayload, RebateState } from '../../typedefs';
import { EMPTY_LIST } from '../../utils/constants';
import type { RebateIncomeQualificationCriteria } from '../../utils/enums';
import RebateUtil from '../../utils/rebateUtil';

const initialState: RebateState = {
  allowIncomeQualificationCriteria: false,
  rebatesAndEligibility: {},
};

const slice = createSlice({
  name: 'rebates',
  initialState,
  reducers: {
    setAllowIncomeQualificationCriteria(state, action: PayloadAction<boolean>) {
      if (state.allowIncomeQualificationCriteria !== action.payload) {
        return { ...state, allowIncomeQualificationCriteria: action.payload };
      }

      return state;
    },
    clearRebates(state) {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchRebatesApiThunk.fulfilled, (state, action) => {
      const { payload } = action;
      const { formVersion } = payload;
      const { formVersion: currentFormVersion } = state;

      if (currentFormVersion && formVersion && currentFormVersion >= formVersion) {
        return;
      }

      return {
        ...state,
        ...payload,
      };
    });
  },
});

export const rebateActions = slice.actions;

const getRebatesAndEligibilityByRecommendationId = (store: RootState) => store.rebates.rebatesAndEligibility;
export const getAllowIncomeQualificationCriteria = (store: RootState) => store.rebates.allowIncomeQualificationCriteria;

export const getUnfilteredRebatesByRecommendationId = createSelector(
  [getRebatesAndEligibilityByRecommendationId],
  (rebatesAndEligibilityByRecommendationId) => {
    const rebatesByRecommendationId = mapValues(rebatesAndEligibilityByRecommendationId, (rebatesAndEligibility) => {
      return rebatesAndEligibility.rebates;
    });
    return rebatesByRecommendationId;
  },
);

export const hasIncomeQualifiedRebatesByRecommendationId = createSelector(
  getRebatesAndEligibilityByRecommendationId,
  (rebatesAndEligibilityByRecommendationId) => {
    return mapValues(rebatesAndEligibilityByRecommendationId, (rebatesAndEligibility) => {
      return rebatesAndEligibility.eligibleForIncomeQualifiedRebates;
    });
  },
);

export const hasIncomeQualifiedRebatesForProject = (store: RootState, project: EquipmentRecommendationAPIPayload) => {
  const hasIncomeQualifiedRebatesFlagByRecommendationId = hasIncomeQualifiedRebatesByRecommendationId(store);
  return hasIncomeQualifiedRebatesFlagByRecommendationId[project.recommendationId] || false;
};

export const hasAnyIncomeBracketedRebatesInRecommendations = createSelector(
  [getRebatesAndEligibilityByRecommendationId, getAllowIncomeQualificationCriteria],
  (rebatesAndEligibilityByRecommendationId, allowIncomeQualificationCriteria) => {
    if (!allowIncomeQualificationCriteria) return false;

    const eligibleForIncomeBracketedRebates = some(
      values(rebatesAndEligibilityByRecommendationId),
      (recommendationRebateAndElibiglity) => recommendationRebateAndElibiglity.eligibleForIncomeBracketedRebates,
    );
    return eligibleForIncomeBracketedRebates;
  },
);

export const getAllIncomeQualificationCriteria = createSelector(
  [getRebatesAndEligibilityByRecommendationId, getAllowIncomeQualificationCriteria],
  (rebatesAndEligibilityByRecommendationId, allowIncomeQualificationCriteria) => {
    if (!allowIncomeQualificationCriteria) return EMPTY_LIST;

    const iqCriteria = flatMap(rebatesAndEligibilityByRecommendationId, (rebatesAndEligibility) => {
      return rebatesAndEligibility.possibleIncomeQualificationCriteria;
    });
    return uniq(filter(iqCriteria)) as RebateIncomeQualificationCriteria[];
  },
);

export const getLargestRebatesByCategory = createSelector(
  [getUnfilteredRebatesByRecommendationId],
  (rebatesByRecommendationId) => {
    const rebates = RebateUtil.findLargestRebates(rebatesByRecommendationId);
    return isEmpty(rebates) ? EMPTY_LIST : rebates;
  },
);

export default slice.reducer;
