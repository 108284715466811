import classnames from 'classnames';
import { useCallback, useState } from 'react';
import { getPartnerSettingWithDefault } from '../../redux/slice/ui';
import { useAppSelector } from '../../redux/store';
import { E_DEN_LONG_NAME, E_DEN_PRIVACY_PAGE, E_DEN_TERMS_AND_CONDITIONS_PAGE } from '../../utils/constants';
import ExternalLink from '../common/ExternalLink';

export default function TermsAndConditions() {
  const privacyPolicyUrl = useAppSelector((state) => {
    return getPartnerSettingWithDefault(state, 'privacyPolicyUrl', E_DEN_PRIVACY_PAGE) as string;
  });
  const termsConditionsUrl = useAppSelector((state) => {
    return getPartnerSettingWithDefault(state, 'termsConditionsUrl', E_DEN_TERMS_AND_CONDITIONS_PAGE) as string;
  });
  const companyName: string = useAppSelector((state) => {
    return getPartnerSettingWithDefault(state, 'name', E_DEN_LONG_NAME) as string;
  });
  const [showTcpaTerms, setShowTcpaTerms] = useState(false);
  const [renderTcpaTerms, setRenderTcpaTerms] = useState(false);

  const onClickShowTcpaTerms = useCallback(() => {
    setRenderTcpaTerms(true);
    setTimeout(() => setShowTcpaTerms(true), 2);
  }, [setRenderTcpaTerms, setShowTcpaTerms]);

  return (
    <div className="TermsAndConditions">
      <p className="mt-3 mb-1 text-center">
        By clicking Submit you agree to our <ExternalLink href={termsConditionsUrl}>Terms and Conditions</ExternalLink>,{' '}
        <ExternalLink href={privacyPolicyUrl}>Privacy Policy</ExternalLink>, and{' '}
        <button className="link-button link-primary" onClick={onClickShowTcpaTerms}>
          TCPA Consent
        </button>
        .
      </p>
      <p className="mb-1 text-center">We respect your privacy.</p>
      <p
        className={classnames('smallprint mb-1 text-center', {
          'd-none': !renderTcpaTerms,
          'd-block': renderTcpaTerms,
        })}
        style={{
          opacity: showTcpaTerms ? '1' : '0',
          transition: 'all .2s',
        }}
      >
        By submitting this form, you are giving your consent to receive phone calls and text messages from{' '}
        {` ${companyName}`}, service professionals representing the company at the phone number you inputted (including
        wireless number if provided). Contacting you is for the purpose of confirming your project and scheduling a home
        visit or a service call. You understand that automated phone technology, by voice or text messages, may be used
        to reach you. Please note that providing consent does not obligate you to purchase or use any product or
        service.
      </p>
    </div>
  );
}
