import dynamic from 'next/dynamic';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

const LiteYouTubeEmbed = dynamic(() => import('react-lite-youtube-embed'), { ssr: false });

type YoutubeVideoProps = {
  id: string;
  title: string;
};

export default function YoutubeVideo(props: Readonly<YoutubeVideoProps>) {
  const { id, title } = props;
  const params = 'rel=0';

  return (
    <LiteYouTubeEmbed id={id} title={title} adNetwork={true} cookie={true} playerClass="playbtn" params={params} />
  );
}
