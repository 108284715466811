import Head from 'next/head';
import { getGlobalContentOverrides, getInstallerLogo, getPartnerName, showInstallerLogo } from '../redux/slice/ui';
import { useAppSelector } from '../redux/store';
import { DEFAULT_IQ_HEADER_TEXT, IMAGE_FOLDER, STEP_NAMES } from '../utils/constants';
import ErrorUtil from '../utils/errorUtil';
import PathUtil from '../utils/pathUtil';
import WindowUtil from '../utils/windowUtil';

const validOgImageTypes = ['png', 'jpg', 'jpeg', 'gif'];

function hasValidOgImageType(logoUrl?: string) {
  if (!logoUrl) return false;
  return validOgImageTypes.some((type) => logoUrl.endsWith(type));
}

function checkLogoUrlImageType(partnerSlug?: string, logoUrl?: string): boolean {
  const validPartnerImage = hasValidOgImageType(logoUrl);
  if (logoUrl && !validPartnerImage) {
    ErrorUtil.logError(new Error(`Invalid og:image type for partner ${partnerSlug} with imageUrl ${logoUrl}`));
    return false;
  }
  return true;
}

function getContent(
  partnerName: string | undefined,
  stepName: string,
  showLogo: boolean,
  logoUrl: string | undefined,
  iqHeaderText: string,
) {
  const browserPath = WindowUtil.getLocationPath();
  const origin = WindowUtil.getLocationOrigin();
  const partnerSlug = PathUtil.getPartnerSlug(browserPath);
  const reallyShowLogo = showLogo && checkLogoUrlImageType(partnerSlug, logoUrl);
  const name = partnerName || 'EDEN';

  return {
    title: `${iqHeaderText} - ${name} - ${stepName}`,
    description:
      'Get an upfront quote for your new heating and cooling system. See rebates, savings and financing options in less than a minute.',
    image: reallyShowLogo ? `${IMAGE_FOLDER}${logoUrl}` : undefined,
    url: `${origin}/${partnerSlug}/${STEP_NAMES.INTRO}`,
    siteName: 'EDEN',
  };
}

export default function InstantQuotePageHead({ stepName }: { stepName: string }) {
  const partnerName = useAppSelector(getPartnerName);
  const showLogo = useAppSelector(showInstallerLogo);
  const logoUrl = useAppSelector(getInstallerLogo);
  const contentOverrides = useAppSelector(getGlobalContentOverrides);
  const iqHeaderText = contentOverrides.iqHeaderText || DEFAULT_IQ_HEADER_TEXT;

  const { title, description, image, url, siteName } = getContent(
    partnerName,
    stepName,
    showLogo,
    logoUrl,
    iqHeaderText,
  );

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      {image && <meta property="og:image" content={image} />}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content="en_US" />
    </Head>
  );
}
