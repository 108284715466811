import classNames from 'classnames';
import { map } from 'lodash-es';
import { getGlobalContentOverrides, getInstallerPhoneNumbers, showPhoneNumbers } from '../../redux/slice/ui';
import { useAppSelector } from '../../redux/store';
import StringUtil from '../../utils/stringUtil';

type ContactTelephoneNumberProps = {
  className?: string;
  inline?: boolean;
};

type TelephoneNumberProps = ContactTelephoneNumberProps & {
  phoneNumber: string;
  prefix?: string;
};

function TelephoneNumber({ className, phoneNumber, prefix, inline }: TelephoneNumberProps) {
  const formattedPhoneNumber = StringUtil.formatPhoneNumber(phoneNumber);
  const prefixStr = !inline && prefix ? `${prefix}: ` : '';
  const suffixStr = inline && prefix ? ` (${prefix})` : '';

  return (
    <span className={className}>
      {prefixStr}
      <a href={`tel:${phoneNumber}`}>{formattedPhoneNumber}</a>
      {suffixStr}
    </span>
  );
}

export default function ContactTelephoneNumber(props: Readonly<ContactTelephoneNumberProps>) {
  const { inline } = props;
  const showPhone = useAppSelector(showPhoneNumbers);
  const phoneNumbers = useAppSelector(getInstallerPhoneNumbers);
  const contentOverrides = useAppSelector(getGlobalContentOverrides);
  const phoneNumberPrefixes = contentOverrides.phoneNumberPrefixes ?? [];

  if (!showPhone || !phoneNumbers) {
    return null;
  }

  const numberOfPhoneNumbers = phoneNumbers.length;

  const parentClassName = inline ? 'd-inline' : 'd-flex flex-column';
  return (
    <div className={parentClassName}>
      {map(phoneNumbers, (phoneNumber, index) => {
        const prefix = phoneNumberPrefixes[index];
        return (
          <div className={classNames(props.className, { 'd-inline': inline })} key={phoneNumber}>
            <TelephoneNumber {...props} phoneNumber={phoneNumber} prefix={prefix} />
            {inline && index < numberOfPhoneNumbers - 1 && <span> / </span>}
          </div>
        );
      })}
    </div>
  );
}
