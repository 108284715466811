// components/Image.js
import NextImage, { ImageProps } from 'next/image';
import { IMAGE_FOLDER } from '../../utils/constants';

export type ObjectFit = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';

type ImageOwnProps = {
  objectFit?: ObjectFit;
  className?: string;
};

export default function Image(props: Readonly<ImageProps & ImageOwnProps>) {
  const { src, objectFit, ...rest } = props;
  const styleProps = {
    objectFit,
  };
  return <NextImage {...rest} src={`${IMAGE_FOLDER}${src}`} style={styleProps} />;
}
