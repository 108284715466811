import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { getFieldErrors } from '../../redux/slice/errors';
import { getFormFieldOverride } from '../../redux/slice/ui';
import type { OverridableFormFields } from '../../typedefs';
import StringUtil from '../../utils/stringUtil';

function mapStateToProps<Type>(store: RootState, ownProps: OwnTypeSelectorProps<Type>) {
  return {
    fieldErrors: getFieldErrors(store),
    formFieldsOverride: getFormFieldOverride(store, ownProps.groupId as OverridableFormFields),
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type TypeOption<Type> = {
  label: string;
  svg?: React.ReactElement;
  type: Type;
};

type OwnTypeSelectorProps<Type> = {
  groupId: string;
  value: Type;
  label?: string;
  largeCol?: number;
  description?: string | React.ReactElement;
  options: TypeOption<Type>[];
  stackOptions?: boolean;
  disabled?: boolean;
  onChange(type: Type): void;
};

type SelectorProps<Type> = OwnTypeSelectorProps<Type> & PropsFromRedux;

function determineOptionId<Type>(groupId: string, value: Type) {
  return `${groupId}-${value}`;
}

function typeOption<Type>({
  option,
  groupId,
  value,
  onChange,
  fieldErrorIds,
  largeCol,
  stackOptions,
  disabled,
}: {
  option: TypeOption<Type>;
  groupId: string;
  value: Type;
  onChange: (type: Type) => void;
  fieldErrorIds: string[];
  largeCol?: number;
  stackOptions?: boolean;
  disabled?: boolean;
}) {
  const { label, svg, type } = option;
  const optionId = determineOptionId(groupId, type);
  const largeColClassName = `col-md-${largeCol}`;
  const selected = value === type;
  const hasError = fieldErrorIds.length;

  return (
    <div
      className={classNames('form-check my-2 align-items-center d-flex', `${groupId}-option`, optionId, {
        [largeColClassName]: !!largeCol,
        'col-md-12 col-lg-6': largeCol === undefined && !stackOptions,
        'w-100': stackOptions,
      })}
      key={optionId}
    >
      <input
        className={classNames('form-check-input', {
          'is-invalid': hasError,
        })}
        type="radio"
        name={groupId}
        id={optionId}
        checked={selected}
        disabled={disabled}
        onChange={() => onChange(type)}
      />
      <label
        className={classNames('form-check-label option rounded', {
          selected,
          'border border-2 border-primary': !hasError && selected,
          'border-danger': hasError,
        })}
        htmlFor={optionId}
      >
        {svg && <div className="SvgHolder d-flex justify-content-center">{svg}</div>}
        <div className={classNames('description', { 'fw-bold': selected })}>{label}</div>
      </label>
    </div>
  );
}

function getDescriptionElement(description: string | React.ReactElement | undefined): React.ReactElement | null {
  if (!description) return null;
  if (typeof description !== 'string') return description;
  return <div className="text-muted">{description}</div>;
}

class TypeSelector<Type> extends React.PureComponent<SelectorProps<Type>> {
  render() {
    const {
      groupId,
      label: labelFromProps,
      description: descriptionFromProps,
      options,
      fieldErrors,
      largeCol,
      formFieldsOverride,
    } = this.props;

    const invalidFieldId = `invalid-radio-feedback-${groupId}`;
    const fieldErrorIds = fieldErrors[groupId] ? fieldErrors[groupId].map((_, idx) => `${invalidFieldId}-${idx}`) : [];

    const label = formFieldsOverride?.label ?? labelFromProps;
    const description = formFieldsOverride?.description ?? descriptionFromProps;

    return (
      <div className="mb-4">
        {label && <div className={classNames({ 'mb-2': !!description })}>{label}</div>}
        {getDescriptionElement(description)}
        <div
          className={classNames('TypeSelector mt-3', {
            'is-invalid': fieldErrorIds.length,
            large: largeCol !== undefined,
            compact: !largeCol,
          })}
          id={groupId}
        >
          {options.map((option) => typeOption<Type>({ ...this.props, option, fieldErrorIds }))}
        </div>
        {fieldErrors[groupId] &&
          fieldErrors[groupId].map((error, idx) => (
            <div id={fieldErrorIds[idx]} className="invalid-feedback" key={StringUtil.hash(error)}>
              {error}
            </div>
          ))}
      </div>
    );
  }
}

export default connector(TypeSelector);
