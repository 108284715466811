import { postData } from '../redux/fetchService/baseFetch';
import type { NeighborhoodAPIPayload } from '../typedefs';
import { createApiThunk } from './utils/ApiThunkCreator';

const setNumberIfPresent = (value: string) => (value !== '' && value !== undefined ? Number(value) : undefined);

export class NeighborhoodAPI {
  fetchNeighborhoodInfo = async (params: {
    zipCode: string;
    partnerSlug: string;
  }): Promise<NeighborhoodAPIPayload | undefined> => {
    const { zipCode, partnerSlug } = params;
    if (!zipCode || !partnerSlug) return undefined;

    return postData('neighborhood', {
      zipCode: setNumberIfPresent(zipCode),
      partnerSlug,
    });
  };
}

export const fetchNeighborhoodInfoApiThunk = createApiThunk(
  'neighborhood/get',
  new NeighborhoodAPI().fetchNeighborhoodInfo,
);
