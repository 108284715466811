import { filter, includes, isEmpty, keys, map, mapValues, pick, toString, values } from 'lodash-es';
import { NormalizedEntitiesAPIPayload, NormalizedSingleEntityAPIPayload } from '../typedefs';

type NormalizationOptions = {
  convertNullsToUndefined: boolean;
};

const MAX_HYDRATION_DEPTH = 3;

function getEntityKey(key: string, keyMappings: { [key: string]: string }): string {
  const pluralizedKey = `${key}s`;
  return keyMappings[key] || pluralizedKey;
}

function convertNullToUndefined(value: any): any {
  return value === null ? undefined : value;
}

function determineEffectiveKey(key: string, keyMappings: { [key: string]: string }, typeKeys: string[]) {
  const pluralizedKey = getEntityKey(key, keyMappings);
  const isPlural = includes(typeKeys, pluralizedKey);
  if (isPlural) return pluralizedKey;

  const isSingle = includes(typeKeys, key);
  if (isSingle) return key;

  return undefined;
}

function hydrateDependents<
  T extends object,
  P extends {
    [key: string]: { [id: string]: any };
  },
>(
  target: T,
  entities: P,
  keyMappings: { [key: string]: string },
  depth: number,
  options: NormalizationOptions,
): T | undefined {
  const typeKeys = keys(entities);
  const { convertNullsToUndefined } = options;

  const hydrateIfEntity = (value: T[keyof T], key: string, obj: T): T[keyof T] => {
    const effectiveKey = determineEffectiveKey(key, keyMappings, typeKeys);

    if (depth >= MAX_HYDRATION_DEPTH || !effectiveKey) {
      return convertNullsToUndefined ? convertNullToUndefined(value) : value;
    }

    const entitiesForType = entities[effectiveKey];

    if (Array.isArray(value)) {
      const dependentKeys = map(value as any[], toString);
      const newEntities = values(pick(entitiesForType, dependentKeys));
      return map(newEntities, (newEntity) =>
        hydrateDependents(newEntity, entities, keyMappings, depth + 1, options),
      ) as T[keyof T];
    } else {
      const dependentKey = `${value}`;
      const newEntity = entitiesForType[dependentKey];
      return hydrateDependents(newEntity, entities, keyMappings, depth + 1, options) as T[keyof T];
    }
  };

  const result = mapValues(target, hydrateIfEntity);
  return isEmpty(result) ? undefined : (result as T);
}

const NormalizationUtil = {
  denormalizeSingleResult<
    T extends object,
    P extends {
      [key: string]: { [id: string]: T };
    },
  >(
    payload: NormalizedSingleEntityAPIPayload<P>,
    typeKey: string,
    keyMappings: { [key: string]: string } = {},
    options: NormalizationOptions = { convertNullsToUndefined: true },
  ): T | undefined {
    if (!payload) return undefined;
    const entitiesForType = payload.entities[typeKey];
    return hydrateDependents(entitiesForType[payload.result], payload.entities, keyMappings, 0, options);
  },
  denormalizeMultipleResults<
    T extends object,
    P extends {
      [key: string]: { [id: string]: T };
    },
  >(
    payload: NormalizedEntitiesAPIPayload<P>,
    typeKey: string,
    keyMappings: { [key: string]: string } = {},
    options: NormalizationOptions = { convertNullsToUndefined: true },
  ): T[] {
    if (!payload) return [];
    const entitiesForType = payload.entities[typeKey];
    const hydrated = map(payload.result, (id) => {
      return hydrateDependents(entitiesForType[id], payload.entities, keyMappings, 0, options);
    });
    return filter(hydrated) as T[];
  },
};

export default NormalizationUtil;
